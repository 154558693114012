import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, SaveButton, Toolbar, useDataProvider, useNotify, required } from 'react-admin';
import { Card, CardContent, CircularProgress, Box, IconButton } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';

const WebsiteIntegration = () => {
    const navigate = useNavigate();

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => navigate(-1)}
                        size="small"
                        >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <h3>Настройка получения заявок с сайтов и сервисов</h3>
                </Box>
                <div>
                    CRM-система уже готова к получению заявок с сайтов на платформе Tilda, с Marquiz, Watbot и с любых других сайтов и сервисов, которые позволяют Вам, или вашему разработчику направить данные в POST-запросе стандартного HTTP-формата.
                    <br/>Здесь, мы покажем, по какому адресу, и с какими аргументами необходимо направлять запросы, для Вашей CRM-системы.
                    <br/>• Тщательно проверяйте корректность введённых данных, чтобы всё работало. 
                    <br/>• Не должно быть пробелов и лишних символов. 
                    <br/>
                    <br/>1. Сначала, Вы должны зайти в CRM-системе в <Link to="/funnels" target="_blank">Редактор воронок</Link> и создать воронку, в которую будут "падать" заявки.
                    <br/>2. В воронке Вы должны вручную обозначить id, цвета и названия этапов данной воронки.
                    <br/>• id каждого этапа должно быть обозначено цифрами или латинскими буквами, без пробелов, для дальнейшего удобства
                    <br/>3. Далее, Вы должны зайти в CRM-системе в <Link to="/requestIncomeSources" target="_blank">Каналы поступления заявок</Link> и создать канал, для вашего источника заявок.
                    <br/>4. Теперь, вы можете снова зайти в созданные Воронку и Канал поступления заявок,
                    <br/>и увидеть сгенерированные системой id для этих сущностей, которые мы используем далее.
                    <br/>
                    <br/><h3>Tilda</h3>
                    <strong>* и другие сайты (сервисы), которые могут направить данные в теле (body) запроса</strong>
                    <br/>Вы должны настроить POST-запрос без авторизации, по адресу:
                    <br /><strong>{endpoint}/tilda</strong>  (для Tilda)
                    <br /><strong>{endpoint}/new_request</strong>  (для других сервисов)
                    <br/>и в теле (body) запроса прописать следующие пары ключ-значение:
                    <br/><strong>funnel_id</strong>: *id воронки, в которой должна появляться заявка*
                    <br/><strong>stage</strong>: *id этапа этой воронки, в котором должна появляться заявка*
                    <br/><strong>income_source_id</strong>: *id канала поступления заявки*
                    <br/><strong>income_source</strong>: *краткое название источника заявки, на латинице, без пробелов*
                    <br/><strong>name</strong>: *имя, которое ввёл клиент, который оставляет заявку*
                    <br/><strong>phone</strong>: *номер телефона, который ввёл клиент, который оставляет заявку*
                    <br/>Для Tilda, эти значения проставляются в настройках полей формы, и называются <strong>Hidden field</strong> (Скрытое поле).
                    <br/>Готово, с этого момента с Tilda и других сайтов (сервисов) в CRM будут падать заявки (если все данные введены корректно).
                    <br/>
                    <br/><h3>Marquiz</h3>
                    Вы должны вставить в "Настройки квиза" - "Интеграции" - "Вебхуки (webhooks)" ссылку в следующем формате:
                    <br/><strong>{endpoint}/mrqz?funnel_id=*id воронки, в которой должна появляться заявка*&stage=*id этапа этой воронки, в котором должна появляться заявка*&income_source_id=*id канала поступления заявки*&income_source=Marquiz</strong> 
                    <br/>И "сохранить" вебхук и квиз.
                    <br/>Пример итоговой ссылки (только пример как может выглядеть ссылка, не для тестирования):
                    <br/>{endpoint}/mrqz?funnel_id=df37d5fa-e2e8-4682-9f97-c796e398dfc6&stage=sale1&income_source_id=c84f7279-8fc5-47d5-b3b6-eb653ab82e6f&income_source=Marquiz 
                    <br/>
                    <br/>Готово, с этого момента с сервиса Marquiz в CRM будут падать заявки (если все данные введены корректно).
                    <br/>
                    <br/><h3>Watbot</h3>
                    Вы должны добавить блок с отправкой данных в CRM (POST-запросом) со ссылкой в следующем формате (аналогично Marquiz):
                    <br/><strong>{endpoint}/watbot?funnel_id=*id воронки, в которой должна появляться заявка*&stage=*id этапа этой воронки, в котором должна появляться заявка*&income_source_id=*id канала поступления заявки*&income_source=Watbot</strong> 
                    <br/>И "сохранить" вебхук и квиз.
                    <br/>Пример итоговой ссылки (только пример как может выглядеть ссылка, не для тестирования):
                    <br/>{endpoint}/watbot?funnel_id=df37d5fa-e2e8-4682-9f97-c796e398dfc6&stage=sale1&income_source_id=c84f7279-8fc5-47d5-b3b6-eb653ab82e6f&income_source=Watbot
                    <br/>
                    <br/>Готово, с этого момента с сервиса Watbot в CRM будут падать заявки (если все данные введены корректно).
                    <br/>
                    <br/>
                    <br/>
                </div>
            </CardContent>
        </Card>
    );
};

export default WebsiteIntegration;
