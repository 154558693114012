import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, SaveButton, Toolbar, useDataProvider, useNotify, required } from 'react-admin';
import { Card, CardContent, CircularProgress, Box, IconButton } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';

const TelphinIntegration = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const { data } = await dataProvider.getOne('integrations', { id: 'telphin' });
            setRecord(data);
            notify('Проверьте внимательно данные интеграции, если сервис не работает.', { type: 'info' });
        } catch (error) {
            notify('Данных текущей интеграции не найдено. Вы можете настроить интеграцию.', { type: 'info' });
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (values) => {
        try {
            await dataProvider.create('integrations', { 
                data: {
                    service: 'telphin',
                    crm_endpoint: endpoint,
                    ...values,
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                } 
            });
            notify('Данные сохранены! Продолжайте настройку Телфин-телефонии', { type: 'success' });
        } catch (error) {
            notify('Ошибка сохранения', { type: 'error' });
        }
    };

    if (loading) return <CircularProgress />;

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => navigate(-1)}
                        size="small"
                        >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <h3>Настройка интеграции Telphin</h3>
                </Box>
                <div>
                    После настройки интеграции, у Вас в CRM будут доступны звонки через Телфин телефонию при нажатии на номера телефонов, а также показаны записи звонков в карточках заявок и сделок, и в разделе "Все звонки".
                    <br/>• Тщательно проверяйте корректность введённых данных, чтобы сервис работал. 
                    <br/>• Не должно быть пробелов и лишних символов. 
                    <br/><strong>• Выполнять шаги строго поэтапно.</strong>
                    <br />
                    <br/>1. Перейдите на сайт <Link to="https://telphin.ru" target="_blank">https://telphin.ru/login</Link> и подайте заявку на подключение Телфин-телефонии.
                    <br/>2. В процессе общения с менеджером Телфин выберите номер телефона (мобильный/городской), количество добавочных для ваших менеджеров, и пришлите карточку ИП/ООО, на который будет подключаться телефония.
                    <br/>• Также сообщите, что хотите приобрести хранилище для записей звонков на 25GB за 490 руб. / мес.
                    <br/>3. Подпишите договор с Телфин и внесите первый платёж с рассчётного счёта, как требует Телфин. Далее можно будет оплачивать сервис с карты.
                    <br/>4. После оплаты, вы получите логин и пароль от 
                    <br/><Link to="https://cabinet.telphin.ru/login" target="_blank">Личного кабинета Телфин</Link>,
                    <br/>в который необходимо войти, и внести паспортные данные директора компании, чтобы телефонию не заблокировали, 
                    <br/>а также, при необходимости, разблокировать в данном кабинете возможность звонков на международные номера (см. тарификацию на сайте Телфин).
                    <br/>
                    <br/>5. В течение суток после оплаты, Вы получите в PDF-файле логин и пароль от 
                    <br/><Link to="https://teleo.telphin.ru/login" target="_blank">кабинета АТС Телфин</Link>, в котором будете управлять телефонией.
                    <br/>
                    <br/><strong>6. Просим обязательно прочитать <strong><Link to="https://teleo.telphin.ru/static/docs/telphin_user_ru_052018.pdf" target="_blank">Руководство пользователя АТС Телфин</Link></strong> человека,
                    <br/>отвечающего за настройку интеграции, перед выполнением следующих шагов</strong>
                    <br/>
                    <br/>7. Теперь, Вам необходимо перейти по ссылке <Link to="https://apiproxy.telphin.ru/login" target="_blank">https://apiproxy.telphin.ru/login</Link>
                    <br/>войти с логином и паролем от АТС, и нажать "Register App"
                    <br/>В поле "Application Name" ввести латинскими буквами без пробелов название Вашей компании (этот пункт не принципиален, можно ввести любое название)
                    <br/>В поле "Redirect URLs" - не вводим ничего
                    <br/>В поле "Application Type - выбираем "trusted"
                    <br/>В поле "Application Access" - выбираем "Call API"
                    <br/>Жмём кнопку "Register", и видим "App ID" и "App Secret"
                    <br/>Внимательно, не выходим с этой страницы, копируем их значения по очереди и вставляем в поля ниже, затем здесь жмём "Сохранить"
                    <SimpleForm record={record} onSubmit={handleSave} toolbar={<Toolbar><SaveButton /></Toolbar>}>
                    <TextInput label="App ID" source="telphin_application_id" helperText="" fullWidth sx={{ marginTop: '1rem' }} />
                    <TextInput label="App Secret" source="telphin_application_secret" helperText='' fullWidth sx={{ marginTop: '1rem' }} />
                    </SimpleForm>
                    <br/>
                    <br/>8. Теперь, Вам необходимо открыть  <Link to="https://teleo.telphin.ru/login" target="_blank">кабинет АТС Телфин</Link>
                    <br/>войти в него с логином и паролем из PDF-файла, полученного выше
                    <br/>и зайти в раздел "Маршрутизация и номера",
                    <br/>и настроить, чтобы на вашем номере была схема, и схему настроить следующим образом (как показано на <Link to="https://dev-realty-host.ru/images/telphin.png" target="_blank">изображении по ссылке</Link> и ниже):
                    <br/>• при добавлении блока "Интерактивная обработка" - выбрать POST и вставить строку:
                    <br/><strong>{endpoint}/ats/telphin/ivr</strong>
                    <br/>
                    <br/>• при добавлении блока "Перевод на номер из переменной" - выбрать "Добавочного" и вставить строку:
                    <br/><strong>transferDestination</strong>
                    <br/>
                    <br/><img src="https://dev-realty-host.ru/images/telphin.png" width="1000rem"/>
                    <br/>
                    <br/>После того, как схема готова, и соответствует данной инструкции, сохраняем схему и выходим на главный экран кабинета АТС.
                    <br/>
                    <br/>9. Далее, добавляем сотрудников - переходим в раздел "Сотрудники и очереди", нажимаем на добавочный номер, 
                    <br/>"Основные настройки" - "Данные сотрудника" - вводим имя и фамилию сотрудника, сохраняем
                    <br/>Далее, там же, вкладка - "Прием звонков" - "Мобильное приложение" - "Сгенерировать QR-код с настройками"
                    <br/>На экране появляется QR-код, делаем скриншот этого QR-кода и отправляем сотруднику, у которого будет этот добавочный номер,
                    <br/>вместе со ссылкой на скачивание мобильного приложения Телфин и следующим текстом:
                    <br/><strong>Cкачать приложение телфин https://www.telphin.ru/products/virtual-atc/softfone и отсканировать QR-код при входе</strong>
                    <br/>
                    <br/>10. Теперь, необходимо правильно внести добавочные номера сотрудников сюда, в CRM:
                    <br/>Заходим в раздел "Пользователи", выбираем нужный аккаунт и жмём "Редактировать"
                    <br/>В поле "Добавочный номер" вводим добавочный номер сотрудника, в формате, как указано в кабинете АТС Телфин,
                    <br/>в разделе "Сотрудники и очереди" - (кликаем на сотрудника) - "Основные настройки" - "Данные для настройки SIP-оборудования" - "Логин"
                    <br/>это будет добавочный в формате <strong>10826*102</strong>
                    <br/>• Проверьте, в строке не должно быть пробелов до или после номера, и лишних символов, иначе телефония не будет работать. 
                    <br/>
                    <br/>11. Готово, можно звонить!
                    <br/>Теперь у Вас в CRM настроены звонки через Телфин телефонию при нажатии на номера телефонов, а также показаны записи звонков в карточках заявок и сделок, и в разделе "Все звонки".
                    <br/>Из звонков от неизвестных ранее клиентов формируются новые заявки, которые распределяются по очереди на менеджеров, подключенных к лидам.
                    <br/>И также, количество звонков и разговорного эфира отображаются по пользователям с добавочным номером в раздела "Аналитика".
                    <br/>
                    <br/><strong>Если у Вас что-то не работает - повторите этап 7, попробуйте пересоздать "доверенное приложение", удалив старое. И введите полученные данные снова.</strong>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </CardContent>
        </Card>
    );
};

export default TelphinIntegration;
