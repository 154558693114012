import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    SimpleList,
    FilterLiveSearch,
    Form,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box, 
    useMediaQuery,
    Icon
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getIconByTalkTime } from './CallsWithContact';

const ListContent  = forwardRef(( props, ref) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {
        data: calls,
        isLoading,
        onToggleItem,
        selectedIds,
        setFilters
    } = useListContext();

    const setListFilter = (field, value) => {
        var dynamicFilter = {};
        dynamicFilter[field] = value;
        setFilters(dynamicFilter);
    };

    useImperativeHandle(ref, () => ({
        setListFilter: (field, value) => setListFilter(field, value),
    }));

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    return (
    <List>
        { calls.map(call => (
          <Box key={call.id} style={{
            border: '1px solid #ccc',
            borderRadius: '1rem',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0.2em'
          }}>
            <Icon sx={{marginRight: '1rem'}}>{getIconByTalkTime(call.Duration)}</Icon>
            <div style={{paddingRight: '1rem'}}>От: {call.manager_name}</div>
            {call.record_link && (
                <audio controls style={{width: '70%', marginRight: '1rem'}}>
                    <source src={call.record_link} type="audio/mpeg" />
                    Браузер не поддерживает аудио.
                </audio>
            )}
            <div style={{paddingRight: '1rem'}}>Время звонка: {new Date(call.EventTime / 1000).toLocaleString()}</div>
          </Box>
        ))}
    </List>
    );
});

const CallsListActions = ({currentManagerFilter, handleSelectedManagerFilterChange}) => (
    <TopToolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>

        {localStorage.getItem('role_id') <= 3 ?
            <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
        </Form> : null}

    </TopToolbar>
);

const CallsList = () => {
    const { identity } = useGetIdentity();
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const listContentRef = useRef();

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
        if (listContentRef.current) {
            listContentRef.current.setListFilter('manager_account_id', value);
        }
    };

    return identity ? (
        <RaList
            sx={{ marginTop: '0.5rem' }}
            actions={ <CallsListActions 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
            /> }
            perPage={50}
            sort={{ field: 'EventTime', order: 'DESC' }}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            filters={[<FilterLiveSearch alwaysOn />]}
        >
            <ListContent ref={listContentRef} />
        </RaList>
    ) : null;
};

export default CallsList;
