import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, SaveButton, Toolbar, useDataProvider, useNotify, required } from 'react-admin';
import { Card, CardContent, CircularProgress, Box, IconButton } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const MangoIntegration = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const { data } = await dataProvider.getOne('integrations', { id: 'wazzup' });
            setRecord(data);
            notify('Проверьте внимательно данные интеграции, если сервис не работает.', { type: 'info' });
        } catch (error) {
            notify('Данных текущей интеграции не найдено. Вы можете настроить интеграцию.', { type: 'info' });
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (values) => {
        try {
            await dataProvider.create('integrations', { 
                data: {
                    service: 'wazzup',
                    ...values,
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                } 
            });
            notify('Данные сохранены! Теперь у Вас в CRM появилась вкладка "Чат с клиентом" в сделках и заявках, проверьте! Если что-то не работает, вернитесь сюда и убедитесь в правильности введённых данных.', { type: 'success' });
        } catch (error) {
            notify('Ошибка сохранения', { type: 'error' });
        }
    };

    if (loading) return <CircularProgress />;

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => navigate(-1)}
                        size="small"
                        >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <h3>Настройка интеграции телефонии Mango</h3>
                </Box>
                <div>
                    После настройки интеграции, у Вас в CRM будут доступны звонки через Телфин телефонию при нажатии на номера телефонов, а также показаны записи звонков в карточках заявок и сделок, и в разделе "Звонки".
                    <br/>• Тщательно проверяйте корректность введённых данных, чтобы сервис работал. 
                    <br/>• Не должно быть пробелов и лишних символов. 
                    <br />
                    Ссылка на кабинет Телфин для создания приложения:
                    <br />
                    <Link to="https://apiproxy.telphin.ru/login" target="_blank">https://apiproxy.telphin.ru/login</Link>
                    <br />
                </div>
                <SimpleForm record={record} onSubmit={handleSave} toolbar={<Toolbar><SaveButton /></Toolbar>}>
                    <TextInput label="Telphin App ID" source="telphin_application_id" helperText="" validate={required()} fullWidth sx={{ marginTop: '1rem' }} />
                    <TextInput label="Telphin App Secret" source="telphin_application_secret" helperText='' validate={required()} fullWidth sx={{ marginTop: '1rem' }} />
                </SimpleForm>
            </CardContent>
        </Card>
    );
};

export default MangoIntegration;
