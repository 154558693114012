import * as React from 'react';
import { useState, useEffect } from 'react';
import {
	ReferenceField,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
	TextField,
	useDataProvider,
	useRedirect,
	useEditContext,
} from 'react-admin';
import { Divider, Box, Grid, Typography, Button, IconButton  } from '@mui/material';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import TelegramIcon from '@mui/icons-material/Telegram';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { typeChoices } from './types';
import { useNavigate } from 'react-router-dom';
import { CallButton} from '../calls/CallButton';
import { usePopup } from '../design/PopupContext'; 

export const RequestInputs = ({ onManagerChange }) => {
	const editContext = useEditContext();
	const dataProvider = useDataProvider();
	const redirect = useRedirect();
	const navigate = useNavigate();
	const { openPopup } = usePopup();
	const [selectedContact, setSelectedContact] = useState();
	const [selectedFunnel, setSelectedFunnel] = useState();
	const [selectedManager, setSelectedManager] = useState();

	const handleContactChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('contacts', { id });
			setSelectedContact(recordFromDatabase.data);
			//onContactChange(recordFromDatabase.data);
		}else{
			setSelectedContact({});
		}
	};

	const handleFunnelChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('funnels', { id });
			setSelectedFunnel(recordFromDatabase.data);
		}else{
			setSelectedFunnel({});
		}
	};

	const handleManagerChange = async(id) => {
		if(id){
			var recordFromDatabase = await dataProvider.getOne('sales', { id });
			setSelectedManager(recordFromDatabase.data);
			onManagerChange(recordFromDatabase.data);
		}else{
			setSelectedManager({});
		}
	};

	const handleStageChange = async(event) => {
		if(editContext.record){
			var recordFromDatabase = await dataProvider.getOne('funnels', { id: editContext.record.funnel_id });

			if(recordFromDatabase.data.stages){
				var scheduledStages = recordFromDatabase.data.stages.filter(stage => stage.meeting_scheduled);

				var scheduledStageIds = scheduledStages.map(stage => stage.id);

				if (scheduledStageIds.includes(event.target.value)) {
					openPopup(editContext.record);
				}
			}
		}
	}

	useEffect(() => {
        const initialContact = editContext && editContext.record ? editContext.record.contact_id : null;
        handleContactChange(initialContact);
		const initialFunnel = editContext && editContext.record ? editContext.record.funnel_id : null;
        handleFunnelChange(initialFunnel);
		const initialManager = editContext && editContext.record ? editContext.record.manager_account_id : null;
        handleManagerChange(initialManager);
    }, [editContext.record]); 


    return (<Box flex="1" mt={-1}>

        
		<Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			{ editContext.record?.name ? <h3>Заявка от "{editContext.record?.name}"</h3> : <h3>Заявка от клиента</h3>}
		</Box>

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
			  <Box display="flex" alignItems="center" justifyContent="start">
			  	{ !selectedContact?.name ? 
				 null : <Box mr={2} mb={2}>
				<IconButton
				  onClick={() => redirect(`/contacts/${selectedContact.id}`)}
				  color="primary"
				  size="small"
				>
				  <EditIcon />
				</IconButton>
				</Box>}
                { !editContext.record ? 
				<TextInput label="ФИО клиента" source="name" fullWidth validate={required()} /> : 
				<ReferenceField source="contact_id" reference="contacts">
					<TextField source="name" />
				</ReferenceField>
				}
				</Box>
            </Grid>
			{ !editContext.record || !selectedContact?.phone ? 
			<Grid item xs={12} sm={6}>
				<TextInput label="Номер телефона" source="phone" fullWidth validate={!editContext.record ? required() : null} />
				{editContext.record && selectedContact && !selectedContact?.phone && 
				<CallButton telegram_username={selectedContact?.telegram_username} add_text="Узнайте номер в телеграм" /> }
			</Grid> :
            <Grid item xs={12} sm={6}>
				<Box display="flex" flexDirection="row" alignItems="center">
				<CallButton contact_phone={selectedContact?.phone} />
				<CallButton contact_phone={selectedContact?.phone} type='link'/>
				</Box>
				{editContext.record && editContext.record.phone_dop_1 ? 
				<Box display="flex" flexDirection="row" alignItems="center">
				<CallButton contact_phone={editContext.record.phone_dop_1} />
				<CallButton contact_phone={editContext.record.phone_dop_1} type='link'/>
				</Box>
				: null}
				{editContext.record && editContext.record.phone_dop_2 ? 
				<Box display="flex" flexDirection="row" alignItems="center">
				<CallButton contact_phone={editContext.record.phone_dop_2} />
				<CallButton contact_phone={editContext.record.phone_dop_2} type='link'/>
				</Box>
				: null}
            </Grid>
			}
        </Grid>

		<Grid container spacing={2} mt={0.5}>
            <Grid item xs={12} sm={6}>
				<TextInput label="Доп. номер 1" source="phone_dop_1" defaultValue="+7" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
				<TextInput label="Доп. номер 2" source="phone_dop_2" defaultValue="+7" fullWidth />
            </Grid>
        </Grid>

        <Box display="flex" alignItems="center" justifyContent="start">
		<Grid container spacing={2}>
		<Grid item xs={12} sm={6}>
			{/* !editContext.record ?
			  <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" onChange={handleManagerChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
              </ReferenceInput> : 
			  <Typography variant="body1">Менеджер: {editContext.record.manager_name}</Typography>
			  */}

            { /*!editContext.record || localStorage.getItem('role_id') <= 3 && (JSON.parse(localStorage.getItem('subordinates_ids')).includes(editContext.record.manager_account_id) || localStorage.getItem('id') === editContext.record.manager_account_id) ?
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" onChange={handleManagerChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} fullWidth validate={required()} />
              </ReferenceInput> : <Typography variant="body1">Менеджер: {editContext.record.manager_name}</Typography>*/}


			  {!editContext.record || (localStorage.getItem('role_id') <= 3 && (() => {
				try {
					const subordinates = localStorage.getItem('subordinates_ids');
					// Check if subordinates_ids exists and is a valid JSON
					if (subordinates) {
						const subordinatesIds = JSON.parse(subordinates);
						return Array.isArray(subordinatesIds) && (subordinatesIds.includes(editContext.record.manager_account_id) || localStorage.getItem('id') === editContext.record.manager_account_id);
					}
					return false;
				} catch (error) {
					console.error('Error parsing subordinates_ids:', error);
					return false;
				}
			})()) ? (
				<ReferenceInput
					source="manager_account_id"
					reference="sales"
					filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}
				>
					<AutocompleteInput
						label="Менеджер"
						onChange={handleManagerChange}
						optionText={(choice) => `${choice.first_name} ${choice.last_name}`}
						fullWidth
						validate={required()}
					/>
				</ReferenceInput>
			) : (
				<Typography variant="body1">
					Менеджер: {editContext.record.manager_name}
				</Typography>
			)}


		</Grid>
            <Grid item xs={12} sm={6}>
		 <SelectInput
			 label="Статус"
			 source="status"
			 choices={typeChoices}
			 fullWidth
         />
		  </Grid>
		</Grid>
        </Box>

		<ReferenceInput source="requestIncomeSource_id" reference="requestIncomeSources">
                    <AutocompleteInput label="Канал поступления заявки" optionText="name" fullWidth />
        </ReferenceInput>

		{/*<ReferenceInput source="village_id" reference="villages">
                    <AutocompleteInput label="Поселок" optionText="name" fullWidth />
        </ReferenceInput>*/}

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                {selectedFunnel?.name && selectedFunnel?.name != 'Реактивация' && localStorage.getItem('role_id') == 4 ? <Typography variant="body1">Воронка: {selectedFunnel?.name}</Typography> : <ReferenceInput source="funnel_id" reference="funnels">
                    <AutocompleteInput label="Воронка" optionText="name" fullWidth onChange={handleFunnelChange} validate={required()} />
                </ReferenceInput>}
            </Grid>
            <Grid item xs={12} sm={6}>
				    {selectedFunnel?.stages ? <SelectInput
                        source="stage"
                        label="Этап"
                        choices={selectedFunnel.stages}
						onChange={handleStageChange}
                        fullWidth
                        validate={required()}
                    /> : null}
            </Grid>
        </Grid>

        <TextInput label="Теги" source="tags" fullWidth multiline rows={2} helperText="Ключевые слова для поиска, через пробел или запятую" />

		<Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
				<TextInput label="Город" source="city" fullWidth helperText={editContext.record ? "Для поиска" : "Город, из которого оставлена заявка (пример: Москва)"} />
            </Grid>
            <Grid item xs={12} sm={6}>
			{editContext.record && 
				<Link 
				to={createDealUrl(
					editContext.record.id, 
					editContext.record.name, 
					editContext.record.number, 
					editContext.record.contact_id, 
					editContext.record.manager_name, 
					editContext.record.manager_account_id, 
					)} target="_blank" >
					<Button variant="outlined" color="primary" fullWidth>
						Создать сделку
					</Button>
				</Link>}
            </Grid>
        </Grid>

        <Divider />

		{ editContext.record?.created_at ?  
			<Typography variant="body1" gutterBottom>Дата создания: {new Date(editContext.record.created_at).toLocaleString('ru-RU')}</Typography> : null}

		{ editContext.record?.updated_at ?  
			<Typography variant="body1" gutterBottom>Дата обновления: {new Date(editContext.record.updated_at).toLocaleString('ru-RU')}</Typography> : null}

		<Divider />
    </Box>);
};

const createDealUrl = (request_id, request_name, request_number, contact_id, manager_name, manager_account_id) => {
    const baseUrl = '/deals/create';
    const params = new URLSearchParams();
    params.append('request_id', request_id);
    params.append('request_name', encodeURIComponent(request_name));
	params.append('request_number', request_number);
	params.append('contact_id', contact_id);
	params.append('manager_name',  encodeURIComponent(manager_name));
	params.append('manager_account_id', manager_account_id);
    return `${baseUrl}?${params.toString()}`;
};

/*<Box mr={2} mb={2}>
				<IconButton
				  onClick={() => redirect('/contacts/create')}
				  color="primary"
				  size="small"
				>
				  <PersonAddIcon />
				</IconButton>
				</Box>*/
