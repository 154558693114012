import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, SaveButton, Toolbar, useDataProvider, useNotify, required } from 'react-admin';
import { Card, CardContent, CircularProgress, Box, IconButton, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import axios from 'axios';
import { endpoint } from '../App';

const WazzupIntegration = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({});
    const [channelData, setChannelData] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const { data } = await dataProvider.getOne('integrations', { id: 'wazzup' });
            setRecord(data);
            notify('Проверьте внимательно данные интеграции, если сервис не работает.', { type: 'info' });
        } catch (error) {
            notify('Данных текущей интеграции не найдено. Вы можете настроить интеграцию.', { type: 'info' });
        } finally {
            setLoading(false);
        }
    };

    const handleGetChannels = async (values) => {
        try {
            const response = await axios.get(
                `https://api.wazzup24.com/v3/channels`,
                {
                  headers: {
                    Authorization: `Bearer ${values.wazzupAuthToken}` 
                  },
                }
              );

            setChannelData(JSON.stringify(response.data));
            notify('Запрос выполнен успешно. Теперь найдите значение channelId для своего номера.', { type: 'info' });
        } catch (error) {
            notify('Ошибка запроса, проверьте, что правильно ввели Ключ API Wazzup и попробуйте ещё раз', { type: 'error' });
        }
    };

    const handleSave = async (values) => {
        try {
            await dataProvider.create('integrations', { 
                data: {
                    service: 'wazzup',
                    ...values,
                    ap_id: localStorage.getItem('id'),
                    ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
                } 
            });
            notify('Данные сохранены! Теперь у Вас в CRM появилась вкладка "Чат с клиентом" в сделках и заявках, проверьте! Если что-то не работает, вернитесь сюда и убедитесь в правильности введённых данных.', { type: 'success' });
        } catch (error) {
            notify('Ошибка сохранения', { type: 'error' });
        }
    };

    const handleSynchronize = async () => {
        try {
            setIsDisabled(true);
            const token = localStorage.getItem('token'); 

            const response = await axios.post(
                `${endpoint}/wazzup/synchronize`,
                {}, 
                {
                  headers: {
                    Authorization: `${token}` 
                  },
                }
              );

            notify(response.data.message, { type: 'info' });
            setIsDisabled(false);
        } catch (error) {
            notify('Ошибка синхронизации, попробуйте ещё раз', { type: 'error' });
            setIsDisabled(false);
        }
    };


    if (loading) return <CircularProgress />;

    return (
        <Card>
            <CardContent>
                <Box display="flex" flexDirection="row">
                    <IconButton
                        onClick={() => navigate(-1)}
                        size="small"
                        >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <h3>Настройка интеграции Wazzup</h3>
                </Box>
                <div>
                    <Link to="https://wazzup24.ru/" target="_blank">Сервис Wazzup - Whatsapp в CRM</Link>   
                    <br />
                    После настройки интеграции, у Вас в CRM активируется вкладка "Чат с клиентом" в карточках заявок и сделок, с сервисом Wazzup.
                    <br/>• Тщательно проверяйте корректность введённых данных, чтобы сервис работал. 
                    <br/>• Не должно быть пробелов и лишних символов. 
                    <br/><strong>• Выполнять шаги строго поэтапно.</strong>
                    <br/>
                    <br />1. Зарегистрируйтесь в сервисе Wazzup, подключите номер телефона и получите Ключ API Wazzup.
                    <br />Ссылка на личный кабинет Wazzup для настройки интеграции:
                    <br />
                    <Link to="https://app.wazzup24.com" target="_blank">https://app.wazzup24.com</Link>
                    <br />
                    <br />Выберите тариф и оплатите Wazzup, подключите по инструкции на сайте Wazzup к смартфону с Whatsapp который будет всегда подключен к интернету и заряжен.
                    <br />
                    <br />2. Получите идентификатор канала channelId сервиса Wazzup.  
                    <br />Вам необходимо ввести ниже Ключ API Wazzup из кабинета Wazzup - раздел "Интеграция с CRM" - "Дополнительно" и после нажатия кнопки "Получить данные с channelId"
                    <br />найти и выбрать channelId в тех же фигурных скобках, что и ваш номер телефона.
                    <br />Ваш номер телефона будет начинаться с "7" и находиться в фигурных скобках после слов "name" и "plainId".
                    <br />Вам нужно скопировать именно уникальное значение, которое находится в кавычках после "channelId", без переносов строк и пробелов:
                    <SimpleForm record={record} onSubmit={handleGetChannels} toolbar={<Toolbar><SaveButton label="Получить данные с channelId" icon={null}/></Toolbar>}>
                    <TextInput label="Ключ API Wazzup" source="wazzupAuthToken" helperText='Из раздела "Интеграция с CRM" -> "Дополнительно"' fullWidth sx={{ marginTop: '1rem' }} />
                    </SimpleForm>
                    Здесь появится набор данных с нужным channelId:
                    <br />
                    <strong>{channelData}</strong>
                    <br />
                    <br />3. Введите в поля ниже полученные данные и нажмите кнопку "Сохранить".

                    <SimpleForm record={record} onSubmit={handleSave} toolbar={<Toolbar><SaveButton /></Toolbar>}>
                    <TextInput label="Ключ API Wazzup" source="wazzupAuthToken" helperText='Из раздела "Интеграция с CRM" -> "Дополнительно"' fullWidth sx={{ marginTop: '1rem' }} />
                    <TextInput label="Идентификатор канала (channelId) Wazzup" source="wazzupChannelId" helperText='Вставлять без кавычек' fullWidth sx={{ marginTop: '1rem' }} />
                    </SimpleForm>

                    <br />4. Обновите страницу личного кабинета Wazzup и проставьте роли пользователей в личном кабинете Wazzup - "Интеграция с CRM" - "Выбрать роли".   
                    <br />5. Нажмите кнопку "Синхронизировать менеджеров и контакты" ниже, чтобы синхронизировать текущие контакты и ответственных менеджеров в сервисе Wazzup.
                    <br />• Вы можете вернуться сюда, если перенесли много заявок на других менеджеров, чтобы синхронизировать контакты для Wazzup заново.
                    <br /><Button onClick={() => handleSynchronize()} disabled={isDisabled} variant="contained" sx={{ marginTop: '1rem', marginBottom: '1rem' }}>Синхронизировать менеджеров и контакты</Button>  
                    <br />6. Готово, проверьте интеграцию, зайдя во вкладку "Чат с клиентом" в карточках заявок и сделок. 
                    <br /><br /><h3>Установка мобильного приложения Wazzup</h3>
                    <strong>1. Добавьте в разделе "Пользователи" CRM личный мобильный номер телефона каждому пользователю 
                    <br />("Пользователи" - "Редактировать" - "Контактные данные" - "Номер телефона" - "Сохранить", в формате +79991234567),
                    <br />который будет использоваться для входа в мобильное приложение Wazzup.</strong>
                    <br />2. Теперь вы можете установить себе и менеджерам мобильное приложение Wazzup и войти в него по номеру телефона, который введён для каждого аккаунта в CRM.
                    <br /><Link to="https://m.wazzup24.com/" target="_blank">Ссылка на скачивание мобильного приложения Wazzup</Link>
                    <br /><Link to="https://web.wazzup24.com/login" target="_blank">Ссылка на веб-версию приложения Wazzup</Link> 
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                
            </CardContent>
        </Card>
    );
};

export default WazzupIntegration;
